import React, { useState, useEffect } from "react";
import {
  ACCESS_TOKEN,
  TENANTS,
  USER_ID,
  USER_INFO,
} from "../constants/AdtechConstants";
import { logout } from "../User/user.service";
import { parseJwt } from "../utils/CommonUtility";

const buttonStyle = {
  display: "block",
  width: "300px",
  textAlign: "center",
  margin: "0 auto",
  cursor: "pointer",
  padding: "10px 15px",
};

const decodeJwt = (token) => {
  if (!token) {
    return null;
  }

  const parts = token.split(".");
  if (parts.length !== 3) {
    throw new Error("JWT must have 3 parts");
  }

  const payload = parts[1]; // The payload is the second part
  const decodedPayload = JSON.parse(atob(payload)); // Decode Base64Url

  return decodedPayload;
};
const getDecodedParam = (params, paramName) => {
  const paramValue = params.get(paramName);
  return paramValue ? atob(decodeURIComponent(paramValue)) : "";
};

const LoginWithSSO = ({
  redirectionUrl,
  redirect,
  tokenKey,
  styles,
  ssoUrl,
  ssoApiConfig,
}) => {
  const [error, setError] = useState(false);
  // const [searchParams, setSearchParams] = useSearchParams();
  const params = new URLSearchParams(window.location.search);
  const handleRedirection = async () => {
    const redirectionRes = await (
      await fetch(ssoUrl, { ...ssoApiConfig })
    ).json();
    window.location.href = redirectionRes?.data?.ssoRedirectUrl;
  };

  const loginResponse = getDecodedParam(params, "samlLoginResponse");
  const logOutResponseStringified = getDecodedParam(
    params,
    "samlLogoutResponse"
  );

  const setLocalStorageParams = (token) => {
    let decodedToken = parseJwt(token);
    console.log(decodedToken);
    localStorage.setItem(USER_INFO, true);
    localStorage.setItem(USER_ID, decodedToken.sub);
    localStorage.setItem(ACCESS_TOKEN, "Bearer " + token);

    let tenants =
      typeof decodedToken.authorities !== "undefined"
        ? Object.keys(decodedToken.authorities)
        : [];

    localStorage.setItem(TENANTS, tenants);
  };
  useEffect(() => {
    const token = localStorage.getItem(tokenKey);
    if (token) {
      redirect();
      return;
    }
    if (loginResponse) {
      const token = JSON.parse(loginResponse)?.data.token;
      let error = JSON.parse(loginResponse)?.status === 400;
      if (!error && token) {
        setLocalStorageParams(token);
        redirect();
      } else {
        const error = JSON.parse(loginResponse)?.data;
        setError(error);
      }
    } else if (logOutResponseStringified) {
      const logOutResponse = JSON.parse(logOutResponseStringified);

      if (logOutResponse?.data) {
        setError(logOutResponse?.data);
      }
    } else {
      handleRedirection();
    }
  }, [loginResponse, logOutResponseStringified]);
  const handleLogout = () => {
    window.open(error?.ssoLogoutRedirectUrl, "_self");
  };
  return (
    <>
      <button style={buttonStyle} onClick={handleRedirection}>
        Login With SSO
      </button>
      {error && (
        <>
          <p style={{ textAlign: "center", color: "red" }}>{error.msg}</p>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {" "}
            <a
              href={error?.ssoLogoutRedirectUrl}
              style={{
                cursor: "pointer",
                color: "blue",
              }}
            >
              Click here
            </a>
            <p style={{ color: "black" }}>&nbsp;to logout from sso</p>
          </div>
        </>
      )}
    </>
  );
};
export default LoginWithSSO;
